import React, { useState } from "react";
import SubItem from "./SubItem";
import ItemRows from "./ItemRows";
import ItemUtil from "./ItemUtil";
import KotUtil from "./KotUtil";
import ItemSearch from "./ItemSearch";
import "./ItemTable.css";
function ItemTable({
  items,
  socket,
  setItems,
  kot,
  setKot,
  total,
  setTotal,
  setCategory,
  setItem,
  item,
  customer,
  initialItems,
  setStep,
  isActive,
  kitchen,
  setKitchen,
  text,
  setText,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [selected, setSelected] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const click = (item, num) => {
    if (item.is_active) {
      if (ItemUtil.hasSubItems(item) && num === 1) {
        setItem(item);
        setSelected([]);
        setQuantity(1);
        setCategory("");
      } else {
        if (item.quantity + num >= 0) {
          ItemUtil.alterItem(item, num);
          kot = KotUtil.alterKot(item, kot, num);
          total = KotUtil.alterKotTotal(item, num, total);
          setItems(items);
          setKot(kot);
          setTotal(total);
        }
      }
    }
  };

  const menuItemClick = (event) => {
    let name = event.target.innerHTML;
    let offset = document.getElementById("menu-" + name).offsetTop + 200;
    setShowMenu(!showMenu);
    window.scrollTo(0, offset - 200);
  };

  const displayCloshButton = () => {
    if (kitchen === "AJs") {
      return (
        <div id="closh-btn-div">
          <img
            style={{ height: "50px", width: "80px" }}
            src="clo.png"
            className="img img-responsive"
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>
      );
    }
  };

  const displayCart = () => {
    if (customer === "{}") {
      document.getElementById("reg-button").click();
    } else if (customer["address"].length === 0) {
      setStep(3);
      document.getElementById("reg-button").click();
    } else {
      document.getElementById("cart-link").click();
    }
  };

  const displayCartButton = () => {
    if (total > 0) {
      return (
        <button id="view-cart-btn" onClick={displayCart}>
          <i className="fas fa-shopping-cart"></i> View Cart &#8377; {total}{" "}
        </button>
      );
    }
  };

  const displayMenu = () => {
    return (
      <div className={"menu-popup " + (showMenu ? "active" : "")}>
        <span onClick={menuItemClick}>Breakfast</span>
        <span onClick={menuItemClick}>Sides</span>
        <span onClick={menuItemClick}>Pizza</span>
        <span onClick={menuItemClick}>Burgers</span>
        <span onClick={menuItemClick}>Sub Station</span>
        <span onClick={menuItemClick}>Mains</span>
        <span onClick={menuItemClick}>Fries/Breads</span>
        <span onClick={menuItemClick}>Desserts</span>
        <span onClick={menuItemClick}>Beverages</span>
      </div>
    );
  };

  const displayMessage = () => {
    if (!isActive) {
      return (
        <div className="inactive-message">
            <p className="text-center iah" >Currently not accepting orders</p>
        </div>
      );
    }else{
      return "";
    }
  };

  return (
    <div id="item-table" className="container-fluid">
      <ItemSearch
        setItems={setItems}
        initialItems={initialItems}
        setText={setText}
        text={text}
      />
      {displayMessage()}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <SubItem
                quantity={quantity}
                setQuantity={setQuantity}
                total={total}
                selected={selected}
                setSelected={setSelected}
                item={item}
                kot={kot}
                setKot={setKot}
                items={items}
                setItems={setItems}
                setItem={setItem}
                setTotal={setTotal}
              />
            </div>
          </div>
        </div>
      </div>
      <ItemRows
        isActive={isActive}
        items={items}
        kitchen={kitchen}
        click={click}
      />
      {displayCloshButton()}
      {displayCartButton()}
      {displayMenu()}
    </div>
  );
}
export default ItemTable;
