import React from 'react';
import ReactDOM from 'react-dom';
import Land from './Land';
import * as serviceWorker from './service-worker';

ReactDOM.render(
  <Land />,
  document.getElementById('root')
);

serviceWorker.register();
