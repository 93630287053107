import React from "react";
import Otp from "./Otp";
import CustomerDetail from "./CustomerDetails";
import "./Registration.css";

function Registration({ setCustomer, step, setStep }) {
  const displayStep = () => {
    if (step === 1) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              <i className="fas fa-address-card"></i> Personal Info (One Time Setup)
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body registration-form">
            <Otp setStep={setStep} />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Verify Otp (One Time Setup)
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body registration-form">
            <Otp setStep={setStep} />
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              <i className="far fa-address-card"></i> Address
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body registration-form">
            <CustomerDetail setCustomer={setCustomer} />
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="modal fade"
      id="reg"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        {displayStep()}
      </div>
    </div>
  );
}

export default Registration;
