import React from "react";
import "./Header.css";
function Header({ customer, kitchen }) {
  const renderHeaderLogo = () => {
    
    if (kitchen === "AJs") {
      return (
        <img
          onClick={() => document.getElementById("home-link").click()}
          src="ajs.png"
          alt=""
          className="header-logo"
        />
      );
    } 
  };

  return (
    <div className="animate__animated animate__fadeIn animate__faster" id="header">
      <div
        onClick={() => document.getElementById("home-link").click()}
        className="header-back"
      >
        <i style={{ fontSize: "25px" }} className="fas fa-arrow-left"></i>
      </div>
      <div className="div-logo">
        {renderHeaderLogo()}
        <div
          onClick={() => document.getElementById("profile-link").click()}
          className="header-profile"
        >
          {customer["name"] ? (
            <React.Fragment>
              <i style={{ fontSize: "25px" }} className="fas fa-user"></i>
              <span className="profile-name">{customer.name}</span>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
