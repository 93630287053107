import React, { useState, useEffect } from "react";
import "./Profile.css";
import Loading from "./Loading";

function Profile({ customer, setStep, socket }) {
  const [kots, setKots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [test, setTest] = useState(false);

  useEffect(() => {
    socket.on("new order", (id) => {
      let customer_id = parseInt(id);
      if (customer.customer_id === customer_id) {
        getCustomerKots();
      }
    });
    socket.on("status", (id) => {
      let customer_id = parseInt(id);
      if (customer.customer_id === customer_id) {
        getCustomerKots();
      }
    });
    getCustomerKots();
    performTest();
  }, []);

  const performTest = () => {
    fetch("index.php?r=site/test", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "") {
          setTest(false);
        } else {
          setTest(true);
        }
      });
  };

  const getCustomerKots = () => {
    setIsLoading(true);
    fetch("index.php?r=kot/get-customer-kots", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "") {
          setKots([]);
        } else {
          setKots(res);
        }
        setIsLoading(false);
      });
  };

  const renderAddress = () => {    
    if (customer["address"]) {
      let addresses = customer.address.map((a) => {        
        return (
          <div key={a.address_id} className="row customer-address">
            <i className="fas fa-address-book"></i>
            <span className="address-name">{a.address}</span>
            <span className="address-name">{a.town}</span>
            <span className="address-landmark">{a.landmark}</span>
          </div>
        );
      });
      return addresses;
    }
    return "";
  };

  const handleClick = () => {
    document.getElementById("reg-button").click();
    setStep(3);
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div id="profile" className="container-fluid">
        {test ? (
          <div className="row text-center">
            <div className="col">
              <button
                onClick={() =>
                  document.getElementById("item-index-link").click()
                }
                className="btn print-btn"
              >
                <i className="fas fa-store"> </i> Item Index
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => document.getElementById("front-link").click()}
                className="btn print-btn"
              >
                <i className="fas fa-archive"> </i> Orders
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {kots.map((kot) => {
          let date = new Date("" + kot.timestamp + "");
          date = date.toString();
          date = date.substr(4, 6) + date.substr(15, 9);
          return (
            <div
              key={kot.kot_id}
              className="kot-div animate__animated animate__slideInLeft animate__faster"
            >
              <i style={{ fontSize: "20px" }} className="fas fa-utensils"></i>
              {kot.kotItems.map((item) => {
                return (
                  <div key={item.kot_item_id} className="row kot-item-row">
                    <div className="col kot-item-col">
                      <span>{item.item_name}</span>
                      {item.kotSubItems.map((sub_item) => {
                        return (
                          <span
                            key={sub_item.sub_item_id}
                            className="cart-sub-items"
                          >
                            {sub_item.item_name}
                          </span>
                        );
                      })}
                    </div>
                    <div className="col kot-quantity-col">{item.quantity}</div>
                  </div>
                );
              })}

              <div className="row">
                <div className="col kot-total-col">&#8377; {kot.amount}</div>
                <div className="col kot-timestamp-col">{date}</div>
              </div>
              {/* <div className="row front-row">
                {kot.status === "out-for-delivery" ? (
                  <div className="col">
                    <i className="fas fa-shipping-fast"> </i> Out For Delivery
                  </div>
                ) : (
                  ""
                )}
                {kot.status === "delivered" ? (
                  <div className="col">
                    <i className="fas fa-check"> </i> Delivered
                  </div>
                ) : (
                  ""
                )}
                {kot.status === "cooking" ? (
                  <div className="col">
                    <i className="fas fa-utensil-store"> </i> Cooking
                  </div>
                ) : (
                  ""
                )}
              </div> */}
            </div>
          );
        })}
        <div className="row  customer-info">
          <span className="customer-name">
            <i className="fas fa-user"></i> {customer.name}
          </span>
          <span className="customer-ph">
            <i className="fas fa-phone"></i> +91 {customer.ph}
          </span>
        </div>
        {renderAddress()}
        <div className="profile-address">
          <button className="btn " onClick={handleClick}>
            Add <i className="fas fa-address-book"></i>
          </button>
        </div>
      </div>
    );
  }
}
export default Profile;
