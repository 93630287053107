import "./Land.css";
import React, { useState, useEffect } from "react";
import ItemTable from "./ItemTable";
import Cart from "./Cart";
import Registration from "./Registration";
import Profile from "./Profile";
import Front from "./Front";
import Header from "./Header";
import ItemIndex from "./ItemIndex";
import "./App.css";
import openSocket from "socket.io-client";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const socket = openSocket("https://ajsgoa.com:8443");
// const socket = openSocket("http://192.168.0.197:5000");

function Land() {
  const [items, setItems] = useState([]);
  const [customer, setCustomer] = useState({});
  const [kot, setKot] = useState({});
  const [category, setCategory] = useState("");
  const [total, setTotal] = useState(0);
  const [initialItems, setinitialItems] = useState(items);
  const [offset, setOffset] = useState(0);
  const [item, setItem] = useState({});
  const [step, setStep] = useState(1);
  const [isActive, setIsActive] = useState(true);
  const [kitchen, setKitchen] = useState("AJs");
  const [text, setText] = useState("");

  const fetchItems = () => {
    fetch("index.php?r=item/get-menu-items")
      .then((res) => res.json())
      .then((res) => {
        setItems(res);
        setinitialItems(res);
      });
  };

  const fetchImages = () => {
    fetch("non.png");
    fetch("veg.png");
    fetch("egg.png");
    fetch("clo.png");
    fetch("ajs.png");
  };

  const fetchCustomer = () => {
    fetch("index.php?r=site/get-customer", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setCustomer(res);
      });
  };

  const fetchRestaurantStatus = () => {
    fetch("index.php?r=site/restaurant", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === 0) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
        setKot({});
        setTotal(0);
        resetItems();
      });
  };

  const removeToggledItem = (id) => {
    setItems((old) => {
      let newItems = old.map((item) => {
        if (item.item_id === id) {
          item.quantity = 0;
          item.is_active = item.is_active === 1 ? 0 : 1;
        }
        return item;
      });
      return newItems;
    });
  };

  const resetItems = () => {
    setItems((old) => {
      let newItems = old.map((item) => {
        item.quantity = 0;
        return item;
      });
      return newItems;
    });
  };

  const removeToggledItemFromKot = (id) => {
    let amount = 0;
    setKot((old) => {
      let newKot = old;
      for (let key in newKot) {
        if (newKot[key].item_id === id) {
          amount = amount + newKot[key].total;
          delete newKot[key];
        }
      }
      return newKot;
    });
    setTotal((old) => old - amount);
  };

  const handelToggle = (id) => {
    let item_id = parseInt(id);
    removeToggledItem(item_id);
    removeToggledItemFromKot(item_id);
  };

  useEffect(() => {
    fetchItems();
    fetchCustomer();
    fetchImages();
    fetchRestaurantStatus();
    socket.on("restaurant", () => fetchRestaurantStatus());
    socket.on("toggle", (id) => handelToggle(id));
  }, []);

  return (
    <div id="App" className="container-fluid">
      <Router>
        <Link className="hidden" id="cart-link" to="/cart">
          Cart
        </Link>
        <Link className="hidden" id="items-link" to="/">
          Items
        </Link>
        <Link className="hidden" id="front-link" to="/front">
          Front
        </Link>
        <Link className="hidden" id="item-index-link" to="/item-index">
          ItemIndex
        </Link>
        <Link className="hidden" id="profile-link" to="/profile">
          Profile
        </Link>
        <Link className="hidden" id="home-link" to="/">
          Menu
        </Link>
        <Switch>
          <Route exact path="/">
            <Header customer={customer} kitchen={kitchen} />
            <Registration
              setCustomer={setCustomer}
              customer={customer}
              step={step}
              setStep={setStep}
            />
            <ItemTable
              socket={socket}
              customer={customer}
              setCustomer={setCustomer}
              setStep={setStep}
              items={items}
              setItems={setItems}
              total={total}
              setTotal={setTotal}
              setOffset={setOffset}
              offset={offset}
              category={category}
              setCategory={setCategory}
              kot={kot}
              setKot={setKot}
              setItem={setItem}
              item={item}
              initialItems={initialItems}
              isActive={isActive}
              kitchen={kitchen}
              setKitchen={setKitchen}
              setText={setText}
              text={text}
            />
          </Route>
          <Route exact path="/profile">
            <Header customer={customer} kitchen={"AJs"} />
            <Registration
              setCustomer={setCustomer}
              customer={customer}
              step={step}
              setStep={setStep}
            />
            <Profile customer={customer} setStep={setStep} socket={socket} />
          </Route>
          <Route exact path="/front">
            <Header customer={customer} kitchen={"AJs"} />
            <Front socket={socket} />
          </Route>
          <Route exact path="/item-index">
            <Header customer={customer} />
            <ItemIndex socket={socket} />
          </Route>
          <Route exact path="/cart">
            <Header customer={customer} kitchen={"AJs"} />
            <Cart
              socket={socket}
              total={total}
              setTotal={setTotal}
              kot={kot}
              setKot={setKot}
              items={items}
              setItems={setItems}
              customer={customer}
            />
          </Route>         
        </Switch>
      </Router>
      <button
        style={{ display: "none" }}
        id="reg-button"
        data-toggle="modal"
        data-target="#reg"
      ></button>
    </div>
  );
}
export default Land;
