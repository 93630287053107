import React, { useState } from "react";
import ItemUtil from "./ItemUtil";
import KotUtil from "./KotUtil";
import "./SubItem.css";
function SubItem({
  items,
  setItems,
  item,
  kot,
  setKot,
  total,
  setTotal,
  setItem,
  selected,
  setSelected,
  quantity,
  setQuantity,
}) {
  
  const [error, setError] = useState(false);

  let sub_item_lenght = 0;

  const getSubItem = (sub_item) => {
    let sub_items_ele = sub_item.map((sub_item) => {
      return (
        <div className="radio-item" key={sub_item.item_id2}>
          <input
            onChange={() => change(sub_item)}
            type="radio"
            name={sub_item.i1}
            id={sub_item.i2}
          />
          <label htmlFor={sub_item.i2} className="sub-item-lable">
            {sub_item.i2}
          </label>
        </div>
      );
    });
    return sub_items_ele;
  };

  const change = (sub_item) => {
    let newSelected = [...selected];
    let foundMatch = false;
    for (let i = 0; i < newSelected.length; i++) {
      if (sub_item.item_id1 === selected[i].item_id1) {
        newSelected[i] = sub_item;
        foundMatch = true;
        break;
      }
    }
    if (!foundMatch) {
      newSelected.push(sub_item);
    }
    setSelected(newSelected);
  };

  const click = (num) => {
    let q = quantity;
    if (q + num > 0) {
      q = q + num;
      setQuantity(q);
    }
  };

  const getNestedSubItem = (sub_items) => {
    let sub_items_ele = sub_items.map((sub_item, index) => {
      let subs = getSubItem(sub_item.sub);
      return (
        <div className="col-md-12 sub-item-col" key={index}>
          <span className="sub-item-header">{sub_item.i2}</span>
          {subs}
        </div>
      );
    });
    return sub_items_ele;
  };

  const submit = () => {
    if (selected.length === sub_item_lenght) {
      setError(false);
      ItemUtil.alterItem(item, quantity);
      KotUtil.alterKotWithSubItems(item, kot, selected, quantity);
      total = KotUtil.alterKotTotal(item, quantity, total);
      setKot(kot);
      setItems(items);
      setTotal(total);
      setItem({});
      setSelected([]);
      setQuantity(1);
      document.getElementById("modal-btn").click();
    } else {
      setError(true);
    }
  };

  const getRows = () => {
    let sub_items = item.sub_items;
    if (item.sub_items) {
      let len = sub_items[0].sub.length;
      if (len > 0) {
        sub_item_lenght = sub_items.length;
        return getNestedSubItem(sub_items);
      } else {
        sub_item_lenght = 1;
        return (
          <div className="col-md-12 sub-item-col">
            <span className="sub-item-header">{sub_items[0].i1}</span>
            {getSubItem(sub_items)}
          </div>
        );
      }
    }
  };

  const renderError = () => {
    if (error) {
      return <p className="error">Select Item(s)</p>;
    }
  };

  return (
    <div id="sub-item" className="container">
      <button
        style={{ display: "none" }}
        id="modal-btn"
        data-toggle="modal"
        data-target="#exampleModalCenter"
      >
        .
      </button>
      <div className="row">{getRows()}</div>
      <div className="sub-item-col">
        <button className="item-table-button btn" onClick={() => click(-1)}>
          -
        </button>
        <span className="item-table-quantity">{quantity}</span>
        <button className="item-table-button btn" onClick={() => click(1)}>
          +
        </button>
      </div>
      <div className="sub-item-col">{renderError()}</div>
      <div className="sub-item-col">
        <button className="btn sub-item-confirm-btn" onClick={submit}>
          Confirm
        </button>
      </div>
    </div>
  );
}
export default SubItem;
