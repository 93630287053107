import React from "react";
class ItemSearch extends React.Component {
  constructor(props) {
    super(props);
  }

  search = (event) => {
    let items = this.props.initialItems;
    let text = event.target.value.toLowerCase();
    items = items.filter((item) => {
      let name = item.name.toLowerCase() + item.category.toLowerCase();
      if (name.includes(text)) {
        return item;
      }
    });

    this.props.setText(text);
    this.props.setItems(items);
  };


  resetSearch = () => {
    this.props.setText("");
    this.props.setItems(this.props.initialItems);
  }

  render() {
    return (
      <div className="item-search">
        <i
          className="fas fa-search"
        ></i>

        <input
          className="item-search-btn"
          value={this.props.text}
          placeholder="Tap to Search"
          onChange={this.search}
        />
        <i
          onClick={this.resetSearch}
          className="fas fa-times"
        ></i>
      </div>
    );
  }
}

export default ItemSearch;
