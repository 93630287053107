import React from "react";
import Loading from "./Loading";
import CartRows from "./CartRows";
import "./Cart.css";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    let address_id = 0;
    if(this.props.customer['address']){
      address_id = this.props.customer.address[0].address_id;
    }
    this.state = {
      address_id: address_id,
      isLoading: false,
      error: false,
    };
  }

  fetchItems = () => {
    fetch("index.php?r=item/get-menu-items")
      .then((res) => res.json())
      .then((res) => {
        this.props.setItems(res);
      });
  };

  click = (key, num) => {
    let kot = this.props.kot;
    let items = this.props.items;
    let total = this.props.total;
    let item = kot[key];
    total = total + num * item.price;
    this.alterItems(items, item, num);
    if (item.quantity + num > 0) {
      item.quantity = item.quantity + num;
      item.total = item.quantity * item.price;
    } else {
      delete kot[key];
    }
    this.props.setKot(kot);
    this.props.setItems(items);
    this.props.setTotal(total);
  };

  alterItems(items, kot_item, num) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].item_id === kot_item.id) {
        items[i].quantity = items[i].quantity + num;
        break;
      }
    }
  }

  displayButton = () => {
    if (this.props.total > 0) {
      return (
        <button onClick={this.order} className="btn cart-submit">
          &#8377; Proceed To Payment
        </button>
      );
    }
  };

  divClick = (a) => {
    this.setState({ address_id: a.address_id });
  };

  order = () => {
    if (this.props.total >= 70) {
      this.setState({ error: false });
      let data = {
        total_price: this.props.total,
        mode: "online",
        kot: this.props.kot,
        address_id: this.state.address_id,
      };
      this.setState({ isLoading: true });
      fetch("index.php?r=kot/create", {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.props.setKot({});
          this.fetchItems();
          this.props.setTotal(0);
          window.location.href = "index.php?r=kot/make-payment&id=" + res; 
          // window.open("index.php?r=kot/make-payment&id=" + res);
          // document.getElementById("profile-link").click();
        });
    } else {
      this.setState({ error: true });
    }
  };

  displayAddress = () => {
    if(this.props.customer['address']){
      let address = this.props.customer.address;
      address = address.map((a) => {
        let className = "address-div";
        if (this.state.address_id === a.address_id) {
          className = "address-div address-selected";
        }
        return (
          <div
            id={a.address_id}
            onClick={() => this.divClick(a)}
            className={className}
          >
            <div className="address-name">{a.address}</div>
            <div className="address-landmark">{a.landmark}</div>
          </div>
        );
      });
      return address;
    }else{
      return "";
    }
    
  };

  displayBackButton = () => {
    return (
      <button
        id="back-btn"
        onClick={() => document.getElementById("items-link").click()}
      >
        Back
      </button>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }
    return (
      <div
        id="cart"
        className=" container-fluid animate__animated animate__fadeIn animate__faster"
      >
        <div className="cart-row-header">
          <i style={{ fontSize: "25px" }} className="fas fa-shopping-cart"></i>
          <span className="cart-header">Cart</span>
        </div>
        <CartRows kot={this.props.kot} click={this.click} />
        <div className="cart-row-total">
          <span className="cart-total">&#8377; {this.props.total}</span>
        </div>

        {this.state.error ? (
          <div className="text-center">
            <p className="animate__animated animate__headShake animate__faster" style={{ color: "red" }}>
              Order total has to be greater than 70
            </p>
          </div>
        ) : (
          ""
        )}

        <div className="cart-row-address">{this.displayAddress()}</div>
        <center>{this.displayButton()}</center>
        {this.displayBackButton()}
      </div>
    );
  }
}
export default Cart;
