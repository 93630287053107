import React from "react";
class CartRows extends React.Component {
  constructor(props) {
    super(props);
    this.getRows = this.getRows.bind(this);
  }

  getRow(kot_item, key) {
    let item_name = kot_item.name;
    let sub_item_name = [];
    for (let i = 0; i < kot_item.sub_items.length; i++) {
      sub_item_name.push(<span className="cart-sub-items">{kot_item.sub_items[i].i2}</span>);
    }
    item_name = (
      <span>
        <span>{item_name}</span>
        {sub_item_name}
      </span>
    );    
    return (
      <div key={kot_item.kot_id} className="cart-row row">
        <div className="col">{item_name}</div>
        <div className="col">
          <button className="item-table-button btn"  onClick={() => this.props.click(key, -1)}>
            -
          </button>
          <span className="cart-quantity">{kot_item.quantity}</span>
          <button className="item-table-button btn" onClick={() => this.props.click(key, 1)}>
            +
          </button>
          <span className="cart-item-price" >&#8377; {kot_item.total}</span>
        </div>
      </div>
    );
  }

  getRows() {
    let kot_items = this.props.kot;
    let rows = [];
    for (let key in kot_items) {
      rows.push(this.getRow(kot_items[key], key));
    }
    return rows;
  }
  render() {
    let rows = this.getRows();
    return rows;
  }
}
export default CartRows;
