import React, { useState, useEffect } from "react";
import "./ItemTable.css";
import ItemSearch from "./ItemSearch";
function ItemIndex({ socket }) {
  const [items, setItems] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [initialItems, setInitialItems] = useState([]);
  const [text, setText] = useState([]);

  const fetchItems = () => {
    fetch("index.php?r=item/get-menu-items")
      .then((res) => res.json())
      .then((res) => {
        setInitialItems(res);
        setItems(res);
      });
  };

  const fetchRestaurantStatus = () => {
    fetch("index.php?r=site/restaurant", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === 0) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
      });
  };

  useEffect(() => {
    fetchRestaurantStatus();
    fetchItems();
  }, []);

  const toggle = (item) => {
    fetch("index.php?r=item/toggle&id=" + item.item_id, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        let newItems = items.map((i) => {
          if (i.item_id === item.item_id) {
            i.is_active = res.is_active;
          }
          return i;
        });
        setItems(newItems);
      });
  };

  const restaurantToggle = () => {
    fetch("index.php?r=site/toggle", {
      method: "POST",
      body: JSON.stringify({ is_active: !isActive }),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setIsActive(!isActive);
      });
  };

  return (
    <div id="item-table" className="container-fluid">
      <ItemSearch
        setItems={setItems}
        initialItems={initialItems}
        setText={setText}
        text={text}
      />
      <table className="table">
        <tbody>
          <tr key={0} className={isActive ? "" : "inactive"}>
            <td>Restaurnat</td>
            <td></td>
            <td>
              <button onClick={() => restaurantToggle()} className="btn">
                Toggle
              </button>
            </td>
          </tr>
          {items.map((item) => {
            return (
              <tr
                key={item.item_id}
                className={item.is_active ? "" : "inactive"}
              >
                <td>{item.name}</td>
                <td>{item.category}</td>
                <td>
                  <button onClick={() => toggle(item)} className="btn">
                    Toggle
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default ItemIndex;
