class Validate {
  static isEmpty(name, value) {
    if (value === "") {
      return name + " Cannot be Empty";
    }
    return false;
  }

  static isPhoneNumber(value) {
    if (value.length !== 10) {
      return "Not a valid Phone Number";
    }
    if (value < 0) {
      return "Not a valid Phone Number";
    }
    return false;
  }

  static isOTP(value) {
    if (value.length !== 6) {
      return "Not a valid OTP";
    }
    if (value < 0) {
      return "Not a valid OTP";
    }
    return false;
  }

  static validateOTP(value, key, object) {
    object[key] = [];
    let msg1 = this.isOTP(value);
    let msg2 = this.isEmpty("OTP", value);
    if (msg1) {
      object[key].push(msg1);
    }
    if (msg2) {
      object[key].push(msg2);
    }
    return object;
  }

  static validateNameAndPh(name, ph, object) {
    object["name"] = [];
    object["ph"] = [];
    let msg1 = this.isPhoneNumber(ph);
    let msg2 = this.isEmpty("Phone Number", ph);
    let msg3 = this.isEmpty("Name", name);
    if (msg1) {
      object["ph"].push(msg1);
    }else if(msg2) {
      object["ph"].push(msg2);
    }
    if (msg3) {
      object["name"].push(msg3);
    }
    return object;
  }

  static validateAddress(address, landmark) {
    let error = { address: [], landmark: [] };
    let isValid = true;
    let msg2 = this.isEmpty("Address", address);
    let msg3 = this.isEmpty("Landmark", landmark);
    if (msg2) {
      error["address"].push(msg2);
      isValid = false;
    }
    if (msg3) {
      error["landmark"].push(msg3);
      isValid = false;
    }
    if (isValid) {
      return false;
    }
    return error;
  }
}
export default Validate;
