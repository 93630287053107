import React from "react";
import Validate from "./Validate";
import Loading from "./Loading";
import "./Otp.css";
class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "ph",
      ph: "",
      name: "",
      otp: "",
      isLoading: false,
      error: { ph: [], otp: [], name: [] },
    };
    this.handelChange = this.handelChange.bind(this);
    this.click = this.click.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.requestOtp = this.requestOtp.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
  }

  handelChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  requestOtp() {
    let data = { phone_number: this.state.ph };
    fetch("index.php?r=site/generate-otp", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {});
  }

  verifyOtp() {
    let data = {
      phone_number: this.state.ph,
      otp: this.state.otp,
      name: this.state.name,
    };
    this.setState({ isLoading: true });
    fetch("index.php?r=site/verify-otp", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "") {
          let error = { otp: ["Invalid Otp"], ph: [] };
          this.setState({ error, isLoading: false });
        } else {
          this.props.setStep(3);
        }
      });
  }

  click() {
    let error = this.state.error;
    console.log(error);

    let mode = this.state.mode;
    if (mode === "ph") {
      let ph = this.state.ph;
      error = Validate.validateNameAndPh(this.state.name, this.state.ph, error);
      if (error["ph"].length === 0 && error["name"].length === 0) {
        this.requestOtp();
        this.setState({ mode: "otp" });
        this.props.setStep(2);
      }
    } else {
      let otp = this.state.otp;
      error = Validate.validateOTP(otp, "otp", error);
      if (error[mode].length === 0) {
        this.verifyOtp();
      }
    }
    this.setState({ error });
  }

  renderError(key) {
    let errors = this.state.error;
    errors = errors[key].map((error) => {
      return <p>{error}</p>;
    });
    return errors;
  }

  renderInput() {
    if (this.state.mode === "ph") {
      return (
        <div>
          <div className="input-wraper">
            <label htmlFor="otp">
              <i className="fas fa-user"></i> Full Name
            </label>
            <input
              onChange={this.handelChange}
              className="form-control"
              value={this.state.name}
              name="name"
            />
            {this.renderError("name")}
          </div>
          <div className="input-wraper">
            <label htmlFor="ph">
              <i className="fas fa-phone"></i> Phone Number
            </label>
            <input
              className="form-control"
              type="number"
              max="9999999999"
              min="1000000000"
              style={{ letterSpacing:"9px", fontSize:"20px" }}
              onChange={this.handelChange}
              name="ph"
              value={this.state.ph}
            />
            {this.renderError("ph")}
          </div>
        </div>
      );
    } else {
      return (
        <div className="input-wraper">
          <div className="text-center">

          <label style={{ fontSize:"23px"}} htmlFor="otp">OTP</label>
          </div>
          <input
            id="otp-field"
            onChange={this.handelChange}
            className="form-control"
            type="number"
            min="999999"
            value={this.state.otp}
            min="100000"
            name="otp"
          />
          {this.renderError("otp")}
          <p
            style={{
              paddingTop:"40px",
              color: "black",
              textAlign: "center",
              fontSize:"23px",
              marginBottom:"0px",
              letterSpacing:"3px"
            }}
          >
            {this.state.ph}
           
          </p>
          <p
            style={{
              textAlign: "center",
            }}
          >
            <a
            style={{
              color:"blue",
              textAlign: "center",
            }}
              onClick={() => {
                this.setState({ mode: "ph" });
                this.props.setStep(1);
              }}
            >
              <i className="fas fa-pencil-alt"></i>
              Edit Phone Number
            </a>
          </p>
        </div>
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    } else {
      return (
        <div id="otp" className="container-fluid">
          {this.renderInput()}
          <div className="btn-wraper">
            <button onClick={this.click} className="btn otp-btn">
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      );
    }
  }
}
export default Otp;
