class ItemUtil {
    
  static alterItem(item, num) {
    if (!item.quantity) {
      item.quantity = 0;
    }
    item.quantity = item.quantity + num;
    if (item.quantity + num < 0) {
      item.quantity = 0;
    }
  }

  static hasSubItems(item) {
    let flag = item.sub_items.length > 0 ? true : false;
    return flag;
  }
}

export default ItemUtil;
