import React, { useState, useEffect } from "react";
import "./Profile.css";
import "./Front.css";
import Loading from "./Loading";

function Front({ socket }) {
  const [kots, setKots] = useState([]);

  useEffect(() => {
    getAllKots();
    socket.on("new order", (msg) => {
      getAllKots();
      playAudio();
    });
  }, []);

  const getAllKots = () => {
    fetch("index.php?r=kot/get-all-kots", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "") {
          setKots([]);
        } else {
          setKots(res);
        }
      });
  };

  const setAsPrinted = (id) => {
    let data = {
      id: id,
    };
    fetch("index.php?r=kot/printed", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
      });
  };

  const setStatus = (id, status) => {
    let data = {
      id: id,
      status: status,
    };
    fetch("index.php?r=kot/status", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {});
  };

  const print = (kot) => {
    let newKots = [...kots];
    newKots = kots.map((k) => {
      if (k.kot_id === kot.kot_id) {
        kot.is_printed = 1;
      }
      return k;
    });
    setAsPrinted(kot.kot_id);
    setKots(newKots);
    postKot(kot);
  };

  const status = (id, status) => {
    let newKots = [...kots];
    newKots = kots.map((k) => {
      if (k.kot_id === id) {
        k.status = status;
      }
      return k;
    });
    setStatus(id, status);
    setKots(newKots);
  };

  const playAudio = () => {
    var x = document.getElementById("myAudio");
    x.play();
  };

  const pauseAudio = () => {
    var x = document.getElementById("myAudio");
    x.pause();
    x.currentTime = 0;
  };

  const postKot = (kot) => {
    fetch("http://localhost:8000/kot/web", {
      method: "POST",
      body: JSON.stringify(kot),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        window.open(
          "http://localhost:8000/kot/view/" + res
        );
      });
  };

  if (kots.length > 0) {
    return (
      <div id="profile" className="container-fluid">
        <div className="text-center">
          <button onClick={pauseAudio} className="btn text-center">
            <i className="fas fa-stop-circle"></i> Stop
          </button>
        </div>
        {kots.map((kot) => {
          let date = new Date("" + kot.timestamp + "");
          date = date.toString();
          date = date.substr(4, 6) + date.substr(15, 9);
          let className = "";
          kot.is_printed ?  className="kot-div printed" : className="kot-div not-printed";
          return (
            <div key={kot.kot_id} className={className}>
              <i style={{ fontSize: "20px" }} className="fas fa-utensils"></i>
              {kot.kotItems.map((item) => {
                return (
                  <div key={item.kot_item_id} className="row kot-item-row">
                    <div className="col kot-item-col">
                      <span>{item.item_name}</span>
                      {item.kotSubItems.map((sub_item) => {
                        return (
                          <span key={sub_item.sub_item_id} className="cart-sub-items">
                            {sub_item.item_name}
                          </span>
                        );
                      })}
                    </div>
                    <div className="col kot-quantity-col">{item.quantity}</div>
                  </div>
                );
              })}
              <div className="row front-row">
                {!kot.is_printed ? (
                  <div className="col">
                    <button
                      onClick={() => print(kot)}
                      className="btn print-btn"
                    >
                      <i className="fas fa-print"> </i> Print
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {kot.status === "cooking" ? (
                  <div className="col">
                    <button
                      onClick={() => status(kot.kot_id, "out-for-delivery")}
                      className="btn print-btn"
                    >
                      <i className="fas fa-shipping-fast"> </i> Out For Delivery
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {kot.status === "out-for-delivery" ? (
                  <div className="col">
                    <button
                      onClick={() => status(kot.kot_id, "delivered")}
                      className="btn print-btn"
                    >
                      <i className="fas fa-check"> </i> Delivered
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {kot.status === "delivered" ? (
                  <div className="col">
                    <i className="fas fa-check"> </i> Delivered
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row front-row">
                <div className="col">&#8377; {kot.amount}</div>
                <div className="col">{date}</div>
              </div>
              <div className="row front-row">
                <div className="col">
                  <i className="fas fa-user"></i>
                  <br /> {kot.customer.name}
                </div>
                <div className="col">
                  <i className="fas fa-phone"></i>
                  <br /> {kot.customer.ph}
                </div>
              </div>
              <div className="row front-row">
                <div className="col">
                  <i className="fas fa-address-book"></i>
                  <br /> {kot.address.address}
                </div>
                <div className="col">
                  <i className="fas fa-landmark"></i>
                  <br /> {kot.address.landmark}
                  <br /> {kot.address.town}
                </div>
              </div>
              <div className="row front-row">
                <div className="col">
                  <i className="fas fa-map-marker-alt"> </i>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://www.google.com/maps?q=" +
                      kot.address.lat +
                      "%2C" +
                      kot.address.lon +
                      "&amp;z=17&amp;hl=en"
                    }
                  >
                    Google Maps
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        <audio id="myAudio">
          <source src="phone.mp3" type="audio/mpeg" />
        </audio>
      </div>
    );
  } else {
    return <Loading />;
  }
}
export default Front;
