import React, { useState, useEffect } from "react";
import "./CustomerDetails.css";
import Validate from "./Validate";
import Select from "react-select";

const options = [
  { value: "Ambora", label: "Ambora" },
  { value: "Aquem", label: "Aquem" },
  { value: "Arlem", label: "Arlem" },
  { value: "Arrosim", label: "Arrosim" },
  { value: "Assolna", label: "Assolna" },
  { value: "Benaulim", label: "Benaulim" },
  { value: "Betalbatim", label: "Betalbatim" },
  { value: "Cansaulim", label: "Cansaulim" },
  { value: "Carmona", label: "Carmona" },
  { value: "Cavelossim", label: "Cavelossim" },
  { value: "Chinchinim", label: "Chinchinim" },
  { value: "Colva", label: "Colva" },
  { value: "Cuncolim", label: "Cuncolim" },
  { value: "Curtorim", label: "Curtorim" },
  { value: "Davorlim", label: "Davorlim" },
  { value: "Dramapur", label: "Dramapur" },
  { value: "Fatrade", label: "Fatrade" },
  { value: "Gogol", label: "Gogol" },
  { value: "Loutolim", label: "Loutolim" },
  { value: "Macazana", label: "Macazana" },
  { value: "Maina Curtorim", label: "Maina Curtorim" },
  { value: "Majorda", label: "Majorda" },
  { value: "Margao", label: "Margao" },
  { value: "Mungul", label: "Mungul" },
  { value: "Navelim", label: "Navelim" },
  { value: "Nuvem", label: "Nuvem" },
  { value: "Nessai", label: "Nessai" },
  { value: "Panzorconi", label: "Panzorconi" },
  { value: "Raia", label: "Raia" },
  { value: "Sao Jose De Areal", label: "Sao Jose De Areal" },
  { value: "Sonsodo", label: "Sonsodo" },
  { value: "Sernabatim", label: "Sernabatim" },
  { value: "Utorda", label: "Utorda" },
  { value: "Varca", label: "Varca" },
  { value: "Velsao", label: "Velsao" },
  { value: "Verna", label: "Verna" },
];

function CustomerDetails({ setCustomer }) {
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [town, setTown] = useState({ value: "Margao", label: "Margao" });
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [error, setError] = useState({ address: [], landmark: [] });

  useEffect(() => {
    getLocation();
  }, [address, landmark]);

  const handleClick = () => {
    let newError = Validate.validateAddress(address, landmark);
    if (newError) {
      setError(newError);
    } else {
      sendAddress();
    }
  };

  const renderError = (key) => {
    let errors = error[key].map((e) => {
      return <p>{e}</p>;
    });
    return errors;
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  };

  const showPosition = (position) => {
    setLon(position.coords.longitude);
    setLat(position.coords.latitude);
  };

  const sendAddress = () => {
    let data = {
      address: address,
      landmark: landmark,
      town: town.value,
      lon: lon,
      lat: lat,
    };
    fetch("index.php?r=site/insert-address", {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCustomer(res);
        document.getElementById("reg-button").click();
      });
  };

  return (
    <div id="customer-details" className="container-fluid">
      <div className="input-wraper">
        <label htmlFor="address">
          <i className="fas fa-address-book"></i> Town / City / Village
        </label>
        ​
        <Select
          value={town}
          className="town-dropdown"
          onChange={(value) => setTown(value)}
          options={options}
        />
      </div>
      <div className="input-wraper">
        <label htmlFor="address">
          <i className="fas fa-address-book"></i> Address
        </label>
        ​
        <textarea
          onChange={(e) => setAddress(e.target.value)}
          className="form-control"
          value={address}
          name="address"
          id="txtArea"
          rows="2"
          cols="70"
        />
        {renderError("address")}
      </div>
      <div className="input-wraper">
        <label htmlFor="landmark">
          <i className="fas fa-landmark"></i> Landmark
        </label>
        <input
          onChange={(e) => setLandmark(e.target.value)}
          className="form-control"
          value={landmark}
          type="text"
          name="landmark"
        />
        {renderError("landmark")}
      </div>
      <br />
      <div className="text-center">
        {lat != 0 ? (
          <React.Fragment>
            <span>Geolocation Obtained</span>
            {/* <br/>
            <br/> */}
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://www.google.com/maps?q=" +
                lat +
                "%2C" +
                lon +
                "&amp;z=17&amp;hl=en"
              }
            >
              Verify Location On Google Maps
            </a> */}
          </React.Fragment>
        ) : (
          <p>Please Activate Geolocation</p>
        )}
      </div>

      <br />
      <br />
      <div className="btn-wraper">
        <button onClick={handleClick} className="btn">
          <i className="far fa-save"></i> Submit
        </button>
      </div>
    </div>
  );
}
export default CustomerDetails;
