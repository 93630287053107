import React from "react";
function ItemRows({ items, click, kitchen, isActive }) {
  const renderButton = (item, modal) => {
    if (item.is_active && isActive) {
      if (item.quantity === 0) {
        return (
          <button
            {...modal}
            className="item-table-single-button btn"
            onClick={() => click(item, 1)}
          >
            ADD +
          </button>
        );
      } else {
        return (
          <span>
            <button
              className="item-table-button btn"
              onClick={() => click(item, -1)}
            >
              -
            </button>
            <span className="item-table-quantity">
              {item.quantity ? item.quantity : 0}
            </span>
            <button
              {...modal}
              className="item-table-button btn"
              onClick={() => click(item, 1)}
            >
              +
            </button>
          </span>
        );
      }
    } else {
      return "";
    }
  };

  const getRow = (item, index) => {
    let className = "";

    if (item.quantity >= 1) {
      className =
        className +
        " item-table-selected  animate__animated animate__headShake";
    }

    if (!item.is_active || !isActive) {
      className = className + " inactive";
    }

    if (index % 2 != 0) {
      className = className + " even";
    }

    let modal = {};
    if (item.sub_items.length > 0) {
      modal = { "data-toggle": "modal", "data-target": "#exampleModalCenter" };
    }

    if (item.image) {
      return (
        <div
          key={item.item_id}
          className={className + " row item-table-outer-row"}
        >
          <div className="row item-table-row">
            <div className="col">
              <img src={"img/" + item.image} className="img-fluid" alt="img" />
            </div>
            <div className="col item-table-image-col">
              <img
                src={item.type + ".png"}
                className="item-table-img"
                alt="img"
              />
              <span className="item-table-name">{item.name}</span>
              <p className="item-table-price">&#8377; {item.price}</p>
              {renderButton(item, modal)}
            </div>
          </div>
          {item.description ? (
            <div className="row">
              <p className="item-table-description">{item.description}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return (
        <div
          key={item.item_id}
          className={className + " row item-table-outer-row"}
        >
          <div className="row item-table-row">
            <div className="col item-table-col">
              <img
                src={item.type + ".png"}
                className="item-table-img"
                alt="img"
              />
              <span className="item-table-name">{item.name}</span>
              <p className="item-table-price">&#8377; {item.price}</p>
            </div>
            <div className="col">{renderButton(item, modal)}</div>
          </div>
          {item.description ? (
            <div className="row">
              <p className="item-table-description">{item.description}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  const getCategoryRow = (item, index) => {
    return (
      <React.Fragment>
        <div
          id={"menu-" + item.category}
          key={item.item_id + 1000}
          className="text-center item-table-category"
        >
          {item.category}
        </div>
        {getRow(item, index)}
      </React.Fragment>
    );
  };

  const getRows = () => {
    let category = "";
    let rows = [];
    if (kitchen == "AJs") {
      rows = items.map((item, index) => {
        if (item.kitchen === kitchen) {
          if (category === item.category) {
            category = item.category;
            return getRow(item, index);
          }
          category = item.category;
          return getCategoryRow(item, index);
        }
      });
    } else {
      rows = items.map((item, index) => {
        if (item.kitchen === kitchen) {
          return getRow(item, index);
        }
      });
    }
    return (
      <div className="animate__animated animate__fadeIn animate__faster">
        {rows}
      </div>
    );
  };

  return getRows();
}
export default ItemRows;
